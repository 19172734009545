let ads = [
  {
    title: "How it is today",
    color: "#F0AB1B",
    text: `
      <h4>More of the same</h4>
      <p>“The idealized beauty standard in Brazil is still a thin white woman, with curves, straight and brown hair. This pattern appears in 63% of TV and Facebook protagonists”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
      <h4>And you still think it's okay?</h4>
      <p>“The ideal male beauty standard in Brazil is white, muscular (abdomen and arms), straight brown hair”.</p>
      <p class="cite"><b>(Todxs, 9º Onda)</b></p>
    `
  },
  {
    title: "How it should be",
    color: "#94C15B",
    text: `
      <h4>Less curves, more real bodies</h4>
      <p>“According to a survey by the Brazilian Association of Technical Standards (ABNT), 76% of Brazilian women have a rectangular body shape, with minimal difference between chest and hips, and a slightly pronounced waist.”</p>
      <p><b>AVAILABLE IN: <a href="https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/" target="_blank" rel="noreferrer">https://veja.abril.com.br/comportamento/maioria-das-brasileiras-nao-tem-o-chamado-corpo-violao-diz-pesquisa/</a></b></p>
      <div class="columns">
        <h4>Less white,<br/> more black, brown,<br/> yellow and indigenous</h4>
        <ul>
          <li>
            <h5>42,7%</h5>
            <p>Minority <br/>White</p>
          </li>
          <li>
            <h5>57,3%</h5>
            <p>Most <br/>Diverse</p>
          </li>
        </ul>
      </div>
      <p class="cite right"><b>Brazilian population (IBGE - PNAD, 2012-2019)</b></p>
    `
  },
]
export function getAdsEN() {
  return ads
}